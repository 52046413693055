import React from 'react'
import { BREAKPOINTS, useViewportSmallerThan } from 'utils/mui'
import { useMemoizedContentGetter } from 'utils/contentful'
import Section from 'components/Section'
import { AchieveTheme, Box } from '@achieve/sunbeam'
import styles from './TrustMarkersSection.module.scss'
import { Typography } from 'components/Contentful'
import { Eyebrow } from 'components/Eyebrow'
import { get as _get } from 'lodash-es'

function TrustMarkersSectionModule({
  title,
  subtitle,
  style,
  trustMarkersGrid,
  jsonStyleOverride,
  backgroundColor,
}) {
  const styleOverrideMap = {
    default: 'default',
    blue: 'blue1',
    black: 'black1',
  }

  const backgroundColorMap = {
    'light-blue': AchieveTheme?.sb?.colors?.neutral?.grey?.[8],
    white: AchieveTheme?.sb?.colors?.neutral?.white,
    grey: AchieveTheme?.sb?.colors?.primary?.highlightBlue,
  }

  const StylesMarkers = {
    default: 'default',
    blue1: 'blue1',
    black1: 'black1',
  }

  style = styleOverrideMap[jsonStyleOverride] ?? style

  let styleSection =
    StylesMarkers[_get(style, 'textContent.content[0].content[0].value', StylesMarkers.default)]

  const isMobile = useViewportSmallerThan(BREAKPOINTS.lg)
  const isExtraLarge = useViewportSmallerThan(BREAKPOINTS.xl)

  const backgroundSection = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return AchieveTheme?.sb?.colors?.primary?.achieveBlue
      case StylesMarkers.black1:
        return AchieveTheme?.sb?.colors?.neutral?.white
      default:
        return AchieveTheme?.sb?.colors?.neutral?.grey?.[8]
    }
  }
  const subtitleVariant = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'displayS10' : 'displayS20'
      case StylesMarkers.black1:
        return 'displayXS30'
      default:
        return isMobile ? 'displayS30' : 'displayM20'
    }
  }
  const subtitleFontWeight = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'regular' : 'bold'
      case StylesMarkers.black1:
        return 'regular'
      default:
        return 'bold'
    }
  }
  const titleMarkerVariant = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'displayM10' : 'displayL10'
      case StylesMarkers.black1:
        return isMobile ? 'displayS30' : isExtraLarge ? 'displayM20' : 'displayL10'
      default:
        return 'displayL10'
    }
  }
  const titleMarkerWeight = () => {
    switch (styleSection) {
      case StylesMarkers.blue1:
        return isMobile ? 'regular' : 'medium'
      case StylesMarkers.black1:
        return isMobile ? 'bold' : 'medium'
      default:
        return 'bold'
    }
  }
  const eyebrowMarkerVariant = () => {
    if (styleSection === StylesMarkers.black1) {
      return 'displayXS30'
    }
    return 'displayS20'
  }
  const subtitleMarkerVariant = () => {
    if (styleSection === StylesMarkers.black1) {
      return 'displayXS30'
    }
    return 'bodyS30'
  }

  return (
    <Section
      backgroundColor={backgroundColorMap[backgroundColor] ?? backgroundSection()}
      data-testid="trust-markers-section"
      contain={false}
      className={styles['trust-markers-container']}
    >
      <Box className={styles['trust-markers-content']} data-marker-style={styleSection}>
        {title?.textContent && (
          <Eyebrow
            content={title?.textContent}
            className={styles['trust-markers-title']}
            variant="displayXS30"
            fontWeight="bold"
          />
        )}
        {subtitle?.textContent && (
          <Typography
            className={styles['trust-markers-subtitle']}
            content={subtitle?.textContent}
            variant={subtitleVariant()}
            fontWeight={subtitleFontWeight()}
          />
        )}
        <Box className={styles['trust-markers-markers']}>
          {trustMarkersGrid &&
            trustMarkersGrid?.gridItems?.map((marker, index) => (
              <Box className={styles['trust-markers-item']} key={'marker-' + index}>
                {marker?.fields?.eyebrow && (
                  <Eyebrow
                    className={styles['trust-markers-item-eyebrow']}
                    content={marker?.fields?.eyebrow}
                    variant={eyebrowMarkerVariant()}
                    fontWeight="bold"
                    black
                  />
                )}
                <Typography
                  content={marker?.fields?.title}
                  variant={titleMarkerVariant()}
                  fontWeight={titleMarkerWeight()}
                  className={styles['trust-markers-item-title']}
                />
                <Typography
                  content={marker?.fields?.subtitle}
                  variant={subtitleMarkerVariant()}
                  className={styles['trust-markers-item-subtitle']}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Section>
  )
}

const TrustMarkersSection = ({ content }) => {
  const { title, subtitle, style, trustMarkersGrid } = useMemoizedContentGetter(content, [
    'title',
    'subtitle',
    'style',
    'trustMarkersGrid',
  ])

  const styleOverride = content?.fields?.additionalConfiguration?.fields?.jsonContent?.styleOverride
  const backgroundColor =
    content?.fields?.additionalConfiguration?.fields?.jsonContent?.backgroundColor

  return (
    <TrustMarkersSectionModule
      title={title}
      subtitle={subtitle}
      style={style}
      jsonStyleOverride={styleOverride}
      backgroundColor={backgroundColor}
      trustMarkersGrid={trustMarkersGrid}
    />
  )
}
export { TrustMarkersSection, TrustMarkersSectionModule }
export default TrustMarkersSection
